import React from "react";

import PaymentResult from "@/components/profile/paymentResult";
import { isBrowser } from "@/utils/env";

import "@/pages/common.scss";

const PaymentResultPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return (
    <PaymentResult />
  );
};

export default PaymentResultPage;
